<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container"></div>
    </nav>

    <div class="page-content py-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="bg-white br-1 p-5">
              <h5 class="text-primary text-center">{{ $t("login") }}</h5>
              <hr />
              <form action="#" @submit.prevent="onSubmit">
                <div class="row flex-column-reverse flex-md-row">
                  <div class="col-md-6">
                    <h6 class="mb-1 text-muted">
                      <strong>{{ $t("new_customers") }}</strong>
                    </h6>
                    <p class="text-dark">{{ $t("register_info") }}</p>
                    <router-link
                      to="/create-account/"
                      class="float-right float-md-left"
                    >
                      <button type="button" class="mt-2 btn btn-secondary">
                        <span>{{ $t("create_account") }}</span>
                        <!-- <i class="icon-long-arrow-right"></i> -->
                      </button>
                    </router-link>
                  </div>
                  <div class="col-md-6 mb-3 mb-md-0">
                    <h6 class="mb-1 text-muted">
                      <strong>{{ $t("registered_customers") }}</strong>
                    </h6>
                    <p class="text-dark">{{ $t("account_login") }}</p>
                    <b-form-group
                      id="email-group"
                      :label="$t('email_address') + '*'"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        type="text"
                        v-on:blur="validateEmail()"
                        :state="form.emailState"
                        :placeholder="$t('email_address_login_form')"
                        aria-describedby="email-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="email-feedback">
                        {{ form.emailError }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                      id="password-group"
                      :label="$t('password') + '*'"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="form.password"
                        :type="form.passwordFieldType"
                        v-on:blur="validatePassword()"
                        :state="form.passwordState"
                        aria-describedby="password-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="password-feedback">
                        {{ form.passwordError }}
                      </b-form-invalid-feedback>
                      <!-- <span class="show-password">
                        <b-link class="span-txt" @click="toggleShowPass">
                          {{
                            form.passwordFieldType === "password"
                              ? $t("show")
                              : $t("hide")
                          }}
                        </b-link>
                      </span> -->
                    </b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <router-link class="text-dark" to="/forgot-password/"
                        ><u>{{
                          $t("password_forgot_login_form")
                        }}</u></router-link
                      >
                      <button
                        type="submit"
                        :class="[
                          'btn',
                          'btn-outline-primary-2 py-4 rounded-lg',
                          isFormValid ? 'btn-active' : '',
                        ]"
                      >
                        <span>{{ $t("Log in") }}</span>
                        <!-- <i class="icon-long-arrow-right"></i> -->
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { checkEmailExist } from "@storefront/core/data-resolver/user";
import { Logger } from "@storefront/core/lib/logger";

export default {
  data() {
    return {
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      form: {
        email: "",
        emailState: null,
        email2: "",
        emailState2: null,
        emailError: "",
        emailError2: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        // passwordTimer: null,
        passwordFieldType: "password",
        checked: [],
      },
      show: true,
    };
  },
  metaInfo() {
    return {
      title: this.$t("login"),
    };
  },
  created() {
    this.handleLoginState(this.isLoggedIn);
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    loginText() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "login_page"
      );
    },
    isFormValid() {
      return (
        this.form.email &&
        this.form.password &&
        this.form.emailState &&
        this.form.passwordState
      );
    },
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.$router.push("/account/");
      }
    },
  },
  methods: {
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }

      this.form.emailState = true;
      return true;
    },
    async validateEmail2() {
      if (this.form.email2.length == 0) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email2)) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email2))) {
        this.form.emailState2 = false;
        this.form.emailError2 = this.$t("email_already_exists");
        return false;
      } else {
        this.$router.push({
          name: "create-account",
          params: { useremail: this.form.email2 },
        });
      }
      this.form.emailState2 = true;

      return true;
    },
    async validatePassword() {
      if (this.form.password.length == 0) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_is_required_field");
        return false;
      }

      this.form.passwordState = true;
      return true;
    },
    async onSubmitEmail() {
      let formValid = true;
      if (this.form.emailState2 != true) {
        this.validateEmail2();
        // if (this.form.emailState2 != true) {
        formValid = false;
        //}
      } else {
        formValid = true;
        this.$router.push("/create-account/");
      }

      Logger.debug("onSubmitEmail", "email2", formValid)();
      // this.$router.push("create-account");
    },
    async onSubmit() {
      let loginOk = false;
      let formValid = true;
      if (this.form.emailState != true) {
        this.validateEmail();
        if (this.form.emailState != true) {
          formValid = false;
        }
      }
      Logger.debug("onSubmit", "email", formValid)();
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }

      if (formValid == true) {
        loginOk = await this.$store.dispatch("user/login", {
          username: this.form.email,
          password: this.form.password,
        });

        if (loginOk == true) {
          const msg = {
            type: "success",
            title: "Login",
            text: this.$t("user_login"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
        Logger.debug("create Account", "retval", loginOk)();
      }
    },

    toggleShowPass() {
      this.form.passwordFieldType =
        this.form.passwordFieldType === "password" ? "text" : "password";
    },
    handleLoginState(isLoggedIn) {
      if (isLoggedIn) {
        this.$router.push("/account/");
      }
    },
  },
};
</script>